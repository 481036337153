<template>
  <div class="d-inline-flex" v-if="selectedLeads.length > 0">
    <div>
      <v-row justify="center" align="start">
        <div class="mx-2">
          <div v-if="selectedLeads" class="font">
            <v-btn id="no-background-hover" disabled text :ripple="false" plain>
              <label class="mx-2 black--text subtitle-2">
                # Leads seleccionados
              </label>
              <label v-if="exportAll" class="mx-2 blue--text subtitle-2">
                <span v-if="filterLeadsSearchedSt" class="mx-2 cyan--text">
                  ( {{ leadsSearchedSt.length }} )
                </span>
                <span v-else class="mx-2 cyan--text">
                  ( {{ leadsTableFooterSt.total }} )
                </span>
              </label>
              <label v-else class="mx-2 blue--text subtitle-2">
                {{ selectedLeads.length }}
              </label>
            </v-btn>
          </div>
        </div>
        <div class="mx-2">
          <div v-if="selectedLeads" class="font">
            <v-btn
              v-if="!exportAll"
              class="mx-2"
              color="primary"
              @click="selectAllLeads"
            >
              Seleccionar todos
              <span v-if="filterLeadsSearchedSt" class="mx-2 cyan--text">
                ( {{ leadsSearchedSt.length }} )
              </span>
              <span v-else class="mx-2 cyan--text">
                ( {{ leadsTableFooterSt.total }} )
              </span>
            </v-btn>
            <v-btn
              v-if="selectedLeads.length > 0"
              class="mx-2"
              icon
              outlined
              @click="clearSelectedLeads"
            >
              <v-icon>
                mdi-close
              </v-icon>
              <!-- <span class="mx-2 cyan--text"> ({{ leads.length }}) </span> -->
            </v-btn>
          </div>
        </div>
        <div class="mr-10 pr-10">
          <v-select
            class="select-export-fields"
            v-model="selectedItemsToExport"
            :items="itemsSelectExport"
            :menu-props="{ maxHeight: '370' }"
            label="Campos a exportar"
            multiple
            small-chips
            append-outer-icon="mdi-clipboard-text-multiple-outline"
            :disabled="selectedLeads.length === 0"
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggle">
                <v-list-item-action>
                  <v-icon
                    :color="
                      selectedItemsToExport.length > 0 ? 'indigo darken-4' : ''
                    "
                    >{{ icon }}</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Todos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption"
                >(+{{ selectedItemsToExport.length - 1 }} otros)</span
              >
            </template>
            <v-icon
              slot="append-outer"
              color="secondary"
              @click="exportClipboard"
              :disabled="
                selectedItemsToExport.length === 0 || allowExport === false
              "
            >
              mdi-clipboard-text-multiple-outline
            </v-icon>
          </v-select>
        </div>
      </v-row>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialogLoading" max-width="400">
        <v-card>
          <v-card-title class="body-1">
            Obteniendo todos los Leads.
          </v-card-title>
          <v-card-text
            >Se aplican los filtros seleccionados al momento de obtener todos
            los Leads.
          </v-card-text>
          <v-card-text class="text-center">
            <v-progress-circular
              :value="20"
              indeterminate
            ></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogSuccess" max-width="400">
        <v-card>
          <v-card-title class="body-1 green--text">
            Los Leads se han copiado.
          </v-card-title>
          <v-card-text
            >Puedes pegar los Leads que se copiaron al portapapeles en un
            archivo Excel o .xls presionando Ctrl + V o Command + V
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogSuccess = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
moment.locale("es-mx");
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      dialogLoading: false,
      allowExport: true,
      dialogSuccess: false,
      exportAll: false,
      role: localStorage.getItem("user_role"),
      listOffAllLeadsToExport: [],
      // en v-select de Vuetify no se puede tener todas las funcionalidades del v-select (como toggle all)
      // si el atributo items es un array de objetos, tiene que ser una Array simple
      // así que para eso se hicieron estas 4 variables
      // obtiene el array de Strings que se ha seleccionado
      selectedItemsToExport: [],
      // array simple con los valores a mostrar para que el usuario seleccione
      itemsSelectExport: [
        "Fecha de registro",
        "Hora de registro",
        "Nombre",
        "Teléfono",
        "Correo",
        "Llegó por",
        "Presupuesto",
        "Moneda",
        "Ubicación",
        "Probable",
        "% Perfilación",
        "Broker asignado",
        "Status",
        "Observaciones",
        "¿Cómo nos encontró?",
        "¿Cómo nos contactó?",
        "Campaña",
        "Segmentación",
        "Anuncio",
        "Formulario",
        "Comentarios",
        "Motivo de descarte",
        "Idioma"
      ],
      // array con los items tipo objetos seleccionados,
      // se asigna su vbalor en un watch dependiendo el valor de selectedItemsToExport
      exportFields: [],
      // lista complete de items tipo objetos que se usará para obtener la información de los leads
      // el valor text debe ser igual al item del array simple asociado (itemsSelectExport)
      fields: [
        {
          text: "Fecha de registro",
          value: "created_at",
          format: "datetime"
        },
        {
          text: "Hora de registro",
          value: "created_at",
          format: "hours"
        },
        {
          text: "Nombre",
          value: "contact_lead_name",
          format: "text"
        },
        {
          text: "Teléfono",
          value: "contact_lead.phone",
          format: "phone"
        },
        {
          text: "Correo",
          value: "contact_lead.email",
          format: "email"
        },
        {
          text: "Llegó por",
          value: "zones",
          format: "text"
        },
        {
          text: "Presupuesto",
          value: "budget",
          format: "price"
        },
        {
          text: "Moneda",
          value: "budget",
          format: "currency"
        },
        {
          text: "¿Cómo nos encontró?",
          value: "contact.source:name",
          format: "text"
        },
        {
          text: "¿Cómo nos contactó?",
          value: "contact.medium:name",
          format: "text"
        },
        //Nuevas columnas agregadas...
        {
          text: "Campaña",
          value: "miscellaneous.marketing:campaign",
          format: "text"
        },
        {
          text: "Segmentación",
          value: "miscellaneous.marketing:adSet",
          format: "text"
        },
        /*  {
          text: "Anuncio",
          value: "miscellaneous.marketing:ad",
          format: "text"
        }, */
        //Mas columnas agregadas en Septiembre 2022
        {
          text: "Formulario",
          value: "miscellaneous.marketing.form",
          format: "text"
        },
        {
          text: "Costo",
          value: "miscellaneous.marketing.cost",
          format: "text"
        },
        {
          text: "Ventas",
          value: "miscellaneous.marketing.sales ",
          format: "text"
        },
        {
          text: "Ingresos",
          value: "miscellaneous.marketing.revenue",
          format: "text"
        },
        {
          text: "Palabra Clave",
          value: "miscellaneous.marketing.utm_term",
          format: "text"
        },
        {
          text: "Anuncio",
          value: "miscellaneous.marketing.ad",
          format: "text"
        },

        //Fin de las columnas agregadas...
        {
          text: "Ubicación",
          value: "contact_lead.location",
          format: "text"
        },
        {
          text: "Probable",
          value: "profile",
          format: "text"
        },
        {
          text: "Observaciones",
          value: "observations_detail",
          format: "text"
        },
        {
          text: "% Perfilación",
          value: "profile_percentage",
          format: "percentage"
        },
        {
          text: "Broker asignado",
          value: "internal_broker.name",
          format: "text"
        },
        {
          text: "Status",
          value: "phase",
          format: "phase"
        },
        {
          text: "Comentarios",
          value: "totalComment",
          format: "text"
        },
        {
          text: "Motivo de descarte",
          value: "discard_observations",
          format: "text"
        },
        {
          text: "Idioma",
          value: "contact.language",
          format: "text"
        }
      ]
    };
  },
  watch: {
    selectedLeads() {
      this.exportAll = false;
    },
    selectedItemsToExport(newValue) {
      if (!newValue || newValue.length === 0) {
        this.exportFields = [];
      }
      this.exportFields = newValue.map(i => {
        return this.fields.find(e => {
          return i === e.text;
        });
      });
    }
  },
  computed: {
    ...mapState({
      selectedLeads: state => state.leads.selectedItems,
      filterLeadsSearchedSt: state => state.leads.filterLeadsSearched,
      leadsSearchedSt: state => state.leads.leadsSearched,
      filtersSelectedSt: state => state.leads.filtersSelected,
      leadsTableFooterSt: state => state.leads.leadsTableFooter
    }),
    isAllItemsSelected() {
      return (
        this.selectedItemsToExport.length === this.itemsSelectExport.length
      );
    },
    isSomeItemsSelected() {
      return this.selectedItemsToExport.length > 0 && !this.isAllItemsSelected;
    },
    icon() {
      if (this.isAllItemsSelected) return "mdi-close-box";
      if (this.isSomeItemsSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    // en el select, el valor es el texto descriptivo
    // esta funcion obtiene el valor de cada item
    selectecItemsExportFields() {
      try {
        const selectedItems = [];
        const self = this;
        if (this.exportFields.length > 0) {
          // el orden de las columnas es dependiendo el orden en que selecciono los campos
          this.exportFields.forEach(element => {
            const found = self.fields.find(i => i.text === element);
            selectedItems.push(found);
          });
        }
        return selectedItems;
      } catch (error) {
        return [];
      }
    }
  },
  methods: {
    ...mapActions({
      fetchLeadsleadsToExportSt: "leads/fetchLeadsToExport"
    }),
    ...mapMutations({
      selectedLeadstMutation: "leads/setSelectedItems"
    }),
    selectAllLeads() {
      this.exportAll = !this.exportAll;
      this.$emit("select", this.exportAll);
      this.allowExport = false;
      this.dialogLoading = true;
      this.fetchLeadsleadsToExportSt({
        filter: this.filtersSelectedSt
      }).then(values => {
        this.listOffAllLeadsToExport = values;
        this.allowExport = true;
        this.dialogLoading = false;
      });
    },
    convertToVariable(obj, path) {
      let arr = path.split(".");
      let temp = obj;
      arr.forEach(e => {
        if (temp[e] == undefined) {
          temp = "-";
          return 0;
        }
        temp = temp[e];
      });
      return temp;
    },
    exportClipboard() {
      if (this.exportAll) {
        if (this.filterLeadsSearchedSt) {
          const dataToExport = this.getFieldsFromList(
            this.leadsSearchedSt,
            this.exportFields
          );
          this.updateClipboard(dataToExport);
        } else {
          //this.dialogLoading = true;
          const dataToExport = this.getFieldsFromList(
            this.listOffAllLeadsToExport,
            this.exportFields
          );
          this.updateClipboard(dataToExport);
          //this.dialogLoading = false;
        }
      } else {
        const dataToExport = this.getFieldsFromList(
          this.selectedLeads,
          this.exportFields
        );
        this.updateClipboard(dataToExport);
      }
    },
    clearSelectedLeads() {
      this.selectedLeadstMutation([]);
    },
    getAllLeadsToExport() {
      this.fetchLeadsleadsToExportSt({
        filter: this.filtersSelectedSt
      });
    },
    getFieldsFromList(list, fields) {
      try {
        if (!list || !fields || list.length === 0 || fields.length === 0) {
          return "";
        } else {
          let csvToExport = "";
          let titlesRow = "";

          fields.forEach(i => {
            titlesRow += i.text + " \t ";
          });
          // Siempre se agrega el ID al final
          titlesRow += "ID \t ";
          // remover el último tab y agregar nueva linea
          titlesRow = titlesRow.slice(0, -3) + " \n ";
          csvToExport += titlesRow;
          // recorrer los leads
          list.map(lead => {
            let l = lead;
            if (l.internal_admin) {
              l.internal_broker = l.internal_admin;
              delete l["internal_admin"];
            }
            let actualRow = "";
            fields.forEach(i => {
              // en caso de querer obtener un valor dentro de un objeto (ejemplo contact_lead.email)
              if (i.value.includes(":")) {
                const objectName = i.value.substring(0, i.value.indexOf("."));
                const nestedOneField = i.value.substring(
                  i.value.indexOf(".") + 1,
                  i.value.indexOf(":")
                );
                const nestedTwoField = i.value.substring(
                  i.value.indexOf(":") + 1,
                  i.value.length
                );
                if (
                  l[objectName] &&
                  l[objectName][nestedOneField] &&
                  l[objectName][nestedOneField][nestedTwoField]
                ) {
                  actualRow +=
                    this.getFormattedValue(
                      l[objectName][nestedOneField][nestedTwoField],
                      i.format
                    ) + " \t ";
                } else {
                  actualRow += "- \t ";
                }
                // actualRow += "xxxxxx \t ";
              } else if (i.value.includes(".")) {
                // obtener el objeto y campo interno
                const objectName = i.value.substring(0, i.value.indexOf("."));
                const nestedField = i.value.substring(
                  i.value.indexOf(".") + 1,
                  i.value.length
                );
                if (l[objectName] && l[objectName][nestedField]) {
                  actualRow +=
                    this.getFormattedValue(
                      l[objectName][nestedField],
                      i.format
                    ) + " \t ";
                } else {
                  actualRow += `${this.convertToVariable(l, i.value)} \t `;
                }
                // actualRow += "aaaaaaaaaaaaaa \t ";
              } else if (i.value === "budget") {
                // caso budget son dos campos (budget y currency)
                let budget = l["budget"] ? l["budget"] : "";
                let currency = l["currency"] ? l["currency"] : "MXN";
                if (budget) {
                  actualRow +=
                    this.getFormattedValue(budget + " " + currency, i.format) +
                    " \t ";
                } else {
                  //Se agrego una condicional para agregar un 0 si no tiene presupuesto...
                  if (i.text == "Presupuesto") {
                    actualRow += "0 \t ";
                  } else {
                    actualRow += "- \t ";
                  }
                }
                // actualRow += "eeeeeeeeeeeeee \t ";
              } else if (i.value === "observations_detail") {
                actualRow += this.textFormatObservationsDetail(
                  l["observations_detail"]
                );

                // actualRow += "iiiiiiiiiiii" + " \t ";
              } else if (i.value === "phase") {
                // caso phase contempla los siguientes campos (phase, tracking_phase y operation_phase)
                actualRow +=
                  this.getFormattedValue(
                    l["phase"] +
                      "#tp#" +
                      l["tracking_phase"] +
                      "#op#" +
                      l["operation_phase"],
                    i.format
                  ) + " \t ";
                // actualRow += "eeeeeeeeeeee" + " \t ";
              } else if (i.value === "comments") {
                // caso comments
                const comments = l["comments"];
                if (comments.length > 0) {
                  comments.map(comment => {
                    actualRow +=
                      `Fecha: ${comment.created_at}, Comentario: ${String(
                        comment.text
                      ).replace(/(\r\n|\n|\r)/gm, "")}` + " \t ";
                  });
                }
                // actualRow += "uuuuuuuuu" + " \t ";
              } else {
                if (l[i.value]) {
                  actualRow +=
                    this.getFormattedValue(l[i.value], i.format) + " \t ";
                } else {
                  actualRow += "- \t ";
                }
              }
            });
            // Siempre se agrega el ID al final
            actualRow += this.getFormattedValue(l["_id"], "text");
            // remover el último tab y agregar nueva linea
            actualRow = actualRow.slice(0, -3) + " \n ";
            csvToExport += actualRow;
          });
          return csvToExport;
        }
      } catch (error) {
        return "";
      }

      // return "123 \t abc \n 456 \t def";
    },
    getFormattedValue(value, format) {
      try {
        let formattedValue = value;
        if (!value) return "-";
        switch (format) {
          case "datetime":
            formattedValue = this.dateFormat(value, false);
            break;
          case "date":
            formattedValue = this.dateFormat(value, false);
            break;
          case "hours":
            formattedValue = this.dateFormat(value, true);
            break;
          case "price": {
            // separar valor del precio y currency. ej '150000 USD'
            const priceValue = value.substring(0, value.indexOf(" "));
            /* const currency = value.substring(
              value.indexOf(" ") + 1,
              value.length
            ); */
            formattedValue = this.priceFormat(priceValue);
            break;
          }
          case "currency": {
            const currency = value.substring(
              value.indexOf(" ") + 1,
              value.length
            );
            formattedValue = this.currencyFormat(currency);
            break;
          }

          case "percentage":
            formattedValue = this.percentageFormat(value);
            break;
          case "phase": {
            // separar valor phase, tracking_phase y operation_phase
            // ej activo - asignado = 'active#tp#assigned#op#'
            // ej en operacion - contratos = 'in-operation#tp##op#contract'
            // ej descartado = 'discarded#tp##op#'
            // ej finalizado = 'finished#tp##op#'
            const phaseValue = value.substring(0, value.indexOf("#tp#"));
            const trackingPhaseValue = value.substring(
              value.indexOf("#tp#") + 4,
              value.indexOf("#op#")
            );
            const operationPhaseValue = value.substring(
              value.indexOf("#op#") + 4,
              value.length
            );
            formattedValue = this.phaseFormat(
              phaseValue,
              trackingPhaseValue,
              operationPhaseValue
            );
            break;
          }
          default:
            formattedValue =
              typeof value === "string" ? this.textFormat(value) : value;
            break;
        }
        return formattedValue;
      } catch (error) {
        return value;
      }
    },
    textFormatObservationsDetail(obj) {
      /* 
      category
      contact_link
      interested_unit
      link
      --se omite lo demas...
      message 
      */

      if (!obj) return "- \t ";
      if (obj.message && obj.message.replace(/\s+/g, "") != "") {
        return `${obj.message.replace(/(\r\n|\n|\r|\t)/gm, "")} \t`;
      }
      return "- \t ";
    },
    textFormat(text) {
      try {
        if (text) {
          return text.replace(/(\r\n|\n|\r|\t)/gm, "");
          //return text;
        } else {
          //return "";
          return "-";
        }
      } catch (error) {
        //error
      }
    },
    // formats
    dateFormat(date, hours) {
      if (!date) return "";
      let format = "L";
      //if (isFullDate) format = "DD-MMM-YYYY h:mm a";
      if (hours) format = "h:mm a";
      let dateParsed = moment(String(date)).format(format);
      return dateParsed;
    },
    priceFormat(price) {
      if (parseFloat(price) <= 0 || isNaN(price)) {
        return "-";
      }
      return parseFloat(price);
      /*   .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
    },

    currencyFormat(currency) {
      return currency;
    },
    percentageFormat(percentage) {
      if (!percentage || isNaN(percentage)) return 0;
      let percentageParsed = parseInt(percentage);
      return percentageParsed + "%";
    },
    phaseFormat(phase, trackingPhase, operationPhase) {
      let phaseParsed = "";
      if (!phase) return "Sin fase";
      if (phase === "discarded") return "Descartado";
      if (phase === "finished") return "Finalizado";
      if (trackingPhase === "unassigned") return "Por Asignar";

      if (phase === "active") {
        switch (trackingPhase) {
          case "assigned":
            phaseParsed = "Asignado";
            break;
          case "to-contact":
            phaseParsed = "Por perfilar";
            break;
          case "searching":
            phaseParsed = "Búsqueda";
            break;
          case "tracking":
            phaseParsed = "Seguimiento";
            break;
          case "scheduled-tour":
            phaseParsed = "Recorrido";
            break;
          case "finished-tour":
            phaseParsed = "Seguimiento post-recorrido";
            break;
          case "offer":
            phaseParsed = "Ofertando";
            break;
          case "downpayment":
            phaseParsed = "Apartado";
            break;
          default:
            phaseParsed = "";
            break;
        }
      }
      if (phase === "in-operation") {
        switch (operationPhase) {
          case "contract":
            phaseParsed = "Promesa";
            break;
          case "closing-trade":
            phaseParsed = "Cierre";
            break;
          default:
            phaseParsed = "Promesa";
        }
      }
      return phaseParsed;
    },
    updateClipboard(newClip) {
      const self = this;
      if (!newClip) {
        return "";
      } else {
        navigator.clipboard.writeText(newClip).then(
          function() {
            /* clipboard successfully set */
            self.dialogSuccess = true;
            self.$snotify.info("Copiado al portapapeles", {
              timeout: 1000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false
            });
          },
          function() {
            /* clipboard write failed */
            self.$snotify.error(
              "Ah ocurrido un problema, inténtelo más tarde",
              {
                timeout: 1500,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false
              }
            );
          }
        );
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.isAllItemsSelected) {
          this.selectedItemsToExport = [];
        } else {
          this.selectedItemsToExport = this.itemsSelectExport.slice();
        }
      });
    }
  },
  created() {
    if (this.role === "developer") {
      const removeItems = [2, 3, 5, 11, 13];
      this.itemsSelectExport = this.itemsSelectExport.filter(
        (itm, idx) => !removeItems.includes(idx)
      );
    }
  }
};
</script>
<style scoped>
.select-export-fields {
  width: 300px;
  max-width: 300px;
}
.select-export-fields.disabled i {
  color: gray !important;
  background-color: gray !important;
}
#no-background-hover::before {
  cursor: default !important;
  background-color: transparent !important;
}
</style>
